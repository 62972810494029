import { TextSelection } from '@tiptap/pm/state';
import {
  BubbleMenu,
  Editor,
  EditorContent,
  JSONContent,
  ReactRenderer,
  useEditor,
} from '@tiptap/react';
import {
  RichTextMenuButtonTypes,
  SuggestionTypes,
  TableActionTypes,
  VariableTypes,
} from 'components/utils/tiptap/tiptapInterfaces';
import 'css/tiptap-default.css';
import 'css/tiptap-table.css';
import { EditorState, RawDraftContentState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { withTiptapPermission } from 'hooks/useAuthentication';
import { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { MentionContext, MentionProvider } from '../module/MentionContext';
import { RichTextMenuBar } from './RichTextMenuBar';
import { getTiptapExtensions } from './TiptapExtensions';
import { TiptapTableActionButtons } from './TiptapTableActionButtons';
import { ImageAlignmentButtons } from './custom-extensions/image/alignImage';
import { EditLink } from './custom-extensions/link-preview/EditLink';
import { EditNumber } from './custom-extensions/link-preview/EditNumber';
import { EditContactNo } from './custom-extensions/mention/EditContactNo';
import { getSelectedNode } from './utils';
import { isDraftJSContent } from '@tiptap/draftjs-to-tiptap';

const EditorContext = createContext<Editor | null>(null);
export const useEditorContext = () => useContext(EditorContext) as Editor;

function convertDraftToHTML(content: RawDraftContentState) {
  const editorState = EditorState.createWithContent(convertFromRaw(content));
  return stateToHTML(editorState.getCurrentContent());
}

interface TiptapProps {
  // id: number;
  initialContent: string | RawDraftContentState | JSONContent | null;
  disabled?: boolean;
  autofocus?: boolean;
  editorClassNames?: string;
  wrapperClassNames?: string;
  onUpdate?: Function;
  onBeforeCreate?: Function;
  onCreate?: Function;
  onSelectionUpdate?: Function;
  onTransaction?: Function;
  onFocus?: Function;
  onBlur?: Function;
  onDestroy?: Function;
  placeholder?: string;
  richTextButtonsShowList?: RichTextMenuButtonTypes[];
  tableActionButtonsShowList?: TableActionTypes[];
  maxLines?: number;
  maxCharacters?: number;
  charCountMode?: 'textSize' | 'nodeSize' | 'tokenSize';
  onLimitReached?: Function;
  suggestionsToExclude?: SuggestionTypes[];
  variablesToExclude?: VariableTypes[];
  minimalEditor?: boolean;
  characterLimitUnit?: string;
  allowNullSuggestionPrefix?: boolean;
}

export const Tiptap = ({
  initialContent,
  editorClassNames,
  disabled,
  onBeforeCreate,
  onCreate,
  onUpdate,
  onSelectionUpdate,
  onTransaction,
  onFocus,
  onBlur,
  onDestroy,
  placeholder,
  wrapperClassNames,
  tableActionButtonsShowList, // TODO: only for knowledgebase
  richTextButtonsShowList,
  maxLines,
  maxCharacters,
  onLimitReached,
  charCountMode,
  autofocus = false, // TODO: only for knowledgebase
  minimalEditor = false, // TODO: only for knowledgebase
  characterLimitUnit = 'characters',
  allowNullSuggestionPrefix = false,
}: TiptapProps) => {
  let content: string | JSONContent | null = '';
  // TODO: check if we still need it
  if (isDraftJSContent(initialContent)) {
    content = convertDraftToHTML(initialContent);
  } else {
    content = initialContent;
  }

  const mention = useContext(MentionContext);
  const mentionRendererRef = useRef<ReactRenderer<unknown, { query: string }> | null>(null);

  const editor = useEditor(
    {
      extensions: getTiptapExtensions(
        mentionRendererRef,
        maxLines,
        maxCharacters,
        placeholder,
        charCountMode,
        minimalEditor,
        allowNullSuggestionPrefix
      ),
      content,
      autofocus,
      editorProps: {
        attributes: {
          class: editorClassNames ? editorClassNames : 'h-full focus:outline-none',
        },
      },
      editable: disabled ? false : true,
      onBeforeCreate: ({ editor }) => {
        onBeforeCreate?.(editor);
      },
      onCreate: ({ editor }) => {
        onCreate?.(editor);
      },
      onUpdate: ({ editor }) => {
        onUpdate?.(editor);
      },
      onSelectionUpdate: ({ editor }) => {
        onSelectionUpdate?.(editor);
      },
      onTransaction: ({ editor, transaction }) => {
        onTransaction?.({ editor, transaction });
      },
      onFocus: ({ editor, event }) => {
        onFocus?.({ editor, event });
      },
      onBlur: ({ editor, event }) => {
        onBlur?.({ editor, event });
      },
      onDestroy: () => {
        onDestroy?.();
      },
    },
    []
  );

  useEffect(() => {
    if (!editor) return;
    editor.storage.MentionStorage = mention;

    const mentionRenderer = mentionRendererRef.current;
    if (!mentionRenderer) return;
    const items = {
      ...mention,
      suggestions: mention.suggestions?.filter((suggestion) =>
        suggestion?.name?.toLowerCase().includes(mentionRenderer.props.query.toLowerCase())
      ),
    };
    // https://github.com/ueberdosis/tiptap/issues/3764#issuecomment-1546629928
    setTimeout(() =>
      mentionRendererRef.current?.updateProps({ ...mentionRendererRef.current.props, items })
    );
  }, [mention]);

  useEffect(() => {
    if (editor && !editor.isDestroyed) {
      // Save cursor position
      const { from, to } = editor.state.selection;

      // Update content
      editor.commands.setContent(initialContent, false, { preserveWhitespace: 'full' });

      // Restore cursor position
      const newFrom = Math.min(from, editor.state.doc.content.size);
      const newTo = Math.min(to, editor.state.doc.content.size);
      const textSelection = new TextSelection(
        editor.state.doc.resolve(newFrom),
        editor.state.doc.resolve(newTo)
      );
      editor.view.dispatch(editor.state.tr.setSelection(textSelection));
    }
  }, [initialContent]);

  useEffect(() => {
    editor?.setEditable(!disabled);
  }, [disabled]);

  const noOfLines = editor?.storage?.characterNLineCount.lines();
  const noOfCharacters = editor?.storage?.characterNLineCount.characters();

  useEffect(() => {
    if (noOfLines === maxLines || noOfCharacters === maxCharacters) {
      onLimitReached?.();
    }
  }, [maxLines, noOfLines, maxCharacters, noOfCharacters]);

  const selectedNode = useMemo(() => getSelectedNode(editor), [editor?.state?.selection]);

  const editorRef = useRef<HTMLDivElement>(null);
  const BubbleMenuComponent = useMemo(() => {
    switch (selectedNode.type) {
      case 'table_cell':
        return (
          <TiptapTableActionButtons
            editor={editor as Editor}
            showList={tableActionButtonsShowList}
          />
        );
      case 'link':
        return (
          <EditLink
            editor={editor as Editor}
            text={selectedNode?.attrs?.title}
            prevLink={selectedNode?.attrs?.href}
          />
        );
      case 'contact_no':
        return (
          <EditContactNo editor={editor as Editor} prevContactNo={selectedNode?.attrs?.name} />
        );
      case 'image':
        return (
          <ImageAlignmentButtons editor={editor as Editor} float={selectedNode?.attrs?.float} />
        );
      case 'number':
        return <EditNumber editor={editor as Editor} />;
      default:
        return null;
    }
  }, [selectedNode]);

  if (!editor) {
    return null;
  }

  return (
    <EditorContext.Provider value={editor}>
      <div
        ref={editorRef}
        className={twMerge(
          'flex w-full flex-col rounded-md',
          wrapperClassNames,
          !disabled && 'h-56 border border-gray-300 bg-white'
        )}
      >
        <div>
          <BubbleMenu editor={editor}>{BubbleMenuComponent}</BubbleMenu>
        </div>
        <div
          className={twMerge(
            '!mr-0 flex flex-1 overflow-y-auto pr-4',
            !disabled && '!mb-0 px-[12px] py-[10px]'
          )}
        >
          <EditorContent className='w-full flex-grow' editor={editor} />
        </div>
        {!disabled && richTextButtonsShowList && (
          <div className='items-centerinline-flex rounded-b-md border-t bg-white'>
            <RichTextMenuBar showList={richTextButtonsShowList} />
          </div>
        )}
      </div>
      {maxCharacters && (
        <div className='mt-1 flex flex-col items-end justify-center'>
          <span className='text-sm text-gray-500'>
            {noOfCharacters}/{maxCharacters}
            {' ' + characterLimitUnit}
          </span>
        </div>
      )}
    </EditorContext.Provider>
  );
};

// TODO: remove after fully open to end-users
export const FeaturedTiptap = withTiptapPermission(Tiptap);

interface TiptapPreviewProps {
  content: any;
  isLineClampTwo?: boolean;
  isListItem?: boolean;
}

// Tiptap readonly for card previews
export const TiptapPreview = ({ content, isLineClampTwo, isListItem }: TiptapPreviewProps) => {
  if (!content) return null;

  return (
    <MentionProvider excludedSuggestionTypes={[]}>
      <Tiptap
        editorClassNames={twMerge(
          isLineClampTwo && 'line-clamp-2',
          isListItem && 'line-clamp-4 text-caption-2'
        )}
        initialContent={content}
        disabled
      />
    </MentionProvider>
  );
};

// TODO: remove after fully open to end-users
export const FeaturedTiptapPreview = withTiptapPermission(TiptapPreview);
