import Mention from '@tiptap/extension-mention';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { MentionNode } from './MentionNode';

export const CustomMention = Mention.extend({
  selectable: true,
  addNodeView() {
    return ReactNodeViewRenderer(MentionNode);
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      name: getAttributeObj('name', 'data-mention-name'),
      code: getAttributeObj('code', 'data-mention-code'),
      type: getAttributeObj('type', 'data-mention-type'),
      decimalPlaces: getAttributeObj('type', 'data-mention-decimal-places'),
      decimalRounding: getAttributeObj('type', 'data-mention-decimal-rounding'),
      hasUnsignedNote: getAttributeObj('type', 'data-mention-has-unsigned-note'),
      days: getAttributeObj('type', 'data-mention-days'),
      past24HoursExcluded: getAttributeObj('type', 'data-mention-past-24hours-excluded'),
      unique_code: getAttributeObj('type', 'data-unique-code'),
      indication: getAttributeObj('type', 'data-indication'),
      amount: getAttributeObj('type', 'data-mention-amount'),
    };
  },
  renderText({ node }) {
    return node.attrs.code || node.attrs.unique_code;
  },
});

const getAttributeObj = (name, attribute) => {
  return {
    default: undefined,
    parseHTML: (element) => element.getAttribute(attribute),
    renderHTML: (attributes) => {
      if (!attributes?.[name]) return {};
      return { [attribute]: attributes?.[name] };
    },
  };
};
