import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { APIResource, APIResourceFormPayload } from 'components/resources/api/types';
import {
  API_RESOURCE_API_URL,
  CALC_INTEGRATION_API_URL,
  CALCULATOR_API_URL,
  CONDITIONAL_TEXT_API_URL,
  CUSTOM_NUMERIC_API_URL,
  FORMULA_API_URL,
  INFOBOX_API_URL,
  INFOBOX_IMAGE_API_URL,
  MODULE_API_URL,
  MODULE_TYPES,
  REFERENCE_MANAGER_API_URL,
  TRIGGER_API_URL,
} from '../constants';
import { JsonLogicResult } from '@react-awesome-query-builder/antd';
import { JsonSwitchGroup } from '@react-awesome-query-builder/core';
import { DraftJSRawType } from '../v2/types/draftJSType';
import { JSONContent } from '@tiptap/react';

export interface WithLastUpdated {
  last_updated: string;
}

// TODO: Clear typing for resources
export interface Trigger extends WithLastUpdated {
  id: string;
  title: string;
  condition?: string;
  candidate_trigger: boolean;

  /** editor contents */
  new_trigger_content?: DraftJSRawType | null;
  trigger_content_tiptap?: JSONContent | null;
}

export interface Infobox extends WithLastUpdated {
  id: string;
  title: string;
  type: string;

  /** editor contents */
  new_text_content?: DraftJSRawType | null;
  text_json_tiptap?: JSONContent | null;
}

export interface Media extends WithLastUpdated {
  id: string;
  title: string;
  type: string;
  new_caption_content: any;
  caption_json_tiptap: any;
}

export interface Formula extends WithLastUpdated {
  id: string;
  name: string;
  formula: string;

  conditional_formulas: {
    builder_condition_json: JsonSwitchGroup;
    condition: string;
    condition_logic: JsonLogicResult;
    formula_text: string;
    is_default: boolean;
    label: string;
    readableCondition: string;
    sortabld_id: string;

    /** editor contents */
    new_formula_text_json: any;
    formula_text_json_tiptap: any;

    id?: number;
  }[];

  /** editor contents */
  new_formula_content: any;
  new_text_content: any;
  formula_json_tiptap: any;
  text_json_tiptap: any;
}

export interface ReferenceManager extends WithLastUpdated {
  id: string;
  source: string;
  url: string;
  is_shortened_source_enabled: boolean;
  shortened_source: string;
}

export interface SmartNote extends WithLastUpdated {
  id: string;
  title: string;
  infobox_type: string;

  /** editor contents */
  new_text_content: any;
  text_json_tiptap: any;
}

export interface CustomNumeric extends WithLastUpdated {
  id: string;
  name: string;
  upper_limit: number;
  lower_limit: number;
  unit: string;
}

export interface Integration extends WithLastUpdated {
  id: string;
  integrated_calculator: string;
  integrated_calculator_info: IntegratedCalculatorInfo;
  module: number;
  numerical_output_mappings: any;
  categorical_output_mappings: any;
}

interface IntegratedCalculatorInfo {
  id: number;
  name: string;
}

export interface ConditionalText extends WithLastUpdated {
  id: string;
  name: string;
  conditions: {
    builder_condition_json: JsonSwitchGroup;
    condition: string;
    condition_logic: JsonLogicResult;
    is_default: boolean;
    label: string;
    position: number;
    sortableId: string;
    text: string;

    /** editor contents */
    text_content: any;
    text_json_tiptap: any;

    id?: number;
  }[];
  is_name_optional?: boolean;
}

export interface ConditionalTextRequest extends Omit<ConditionalText, 'id' | 'last_updated'> {
  id?: string;
  module?: number | string;
  calculator?: number | string;
  conditions: any[];
}

/** Resource Paylods */
// TODO: The Payload interface below contains more detailed information than the above interface.
// need to refactoring based on the following interface.

export interface TriggerPayload {
  // TODO: Both value (calculator, module) are required even though API only uses one of them
  module: number | string;
  calculator: number | string;

  title: string;
  condition: string;

  /** editor contents */
  new_trigger_content?: DraftJSRawType | null;
  trigger_content_tiptap?: JSONContent | null;

  // TODO: need to check exact types below
  choiceMapping: { [key: number]: { [key: string]: string } };
  condition_json_tree: JsonSwitchGroup;
  condition_logic: any | null;
  rawCondition: string;

  id?: number;
}

export interface InfoboxPayload {
  module: number | string | null;
  calculator?: number | string | null;
  urls?: { name: string; url: string }[] | null;
  references?: number[];
  title: string;
  subtitle?: string | null;
  is_subtitle_enabled: boolean;
  shortened_title?: string | null;
  text: string;
  video_url?: string | null;
  media?: File | null;
  infobox_type?: 'IG' | 'NG';

  /** editor contents */
  new_text_content?: DraftJSRawType | null;
  text_json_tiptap?: JSONContent | null;

  // TODO: need to check exact types below
  id?: number;
}

export interface FormulaPayload {
  module: number | string;
  is_conditional: boolean;
  name: string;
  shortened_name: string;
  unit: string;
  formula: string;
  text: string;
  conditional_formulas: {
    builder_condition_json: JsonSwitchGroup;
    condition: string;
    condition_logic: JsonLogicResult;
    formula_text: string;
    is_default: boolean;
    label: string;
    readableCondition: string;
    sortabld_id: string;

    /** editor contents */
    new_formula_text_json?: DraftJSRawType | null;
    formula_text_json_tiptap?: JSONContent | null;

    id?: number;
  }[];

  /** editor contents */
  new_formula_content?: DraftJSRawType | null;
  new_text_content?: DraftJSRawType | null;
  formula_json_tiptap?: JSONContent | null;
  text_json_tiptap?: JSONContent | null;

  // TODO: need to check exact types below
  id?: number;
}

export interface ConditionalTextPayload {
  module?: number | string | null;
  calculator?: number | string | null;
  is_name_optional: boolean;
  name: string;
  conditions: {
    builder_condition_json: JsonSwitchGroup;
    condition: string;
    condition_logic: JsonLogicResult;
    is_default: boolean;
    label: string;
    position: number;
    sortableId: string;
    text: string;

    /** editor contents */
    text_content?: DraftJSRawType | null;
    text_json_tiptap?: JSONContent | null;

    id?: number;
  }[];
}

export const resourcesAPI = {
  getTriggers: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Trigger[]>(`${MODULE_API_URL}${queryKey[1]}/triggers`).then((res) => res.data),
  deleteTrigger: (triggerId: string) => axios.delete(TRIGGER_API_URL + triggerId + '/'),
  getInfoboxes: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Infobox[]>(`${MODULE_API_URL}${queryKey[1]}/infoboxes`).then((res) => res.data),
  deleteInfobox: (infoboxId: string) => axios.delete(INFOBOX_API_URL + infoboxId + '/'),
  getMedia: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Media[]>(`${MODULE_API_URL}${queryKey[1]}/images`).then((res) => res.data),
  deleteMedia: (mediaId: string) => axios.delete(INFOBOX_IMAGE_API_URL + mediaId + '/'),
  getFormulas: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Formula[]>(`${MODULE_API_URL}${queryKey[1]}/formulas`).then((res) => res.data),
  deleteFormula: (formulaId: string) => axios.delete(FORMULA_API_URL + formulaId + '/'),
  getReferences: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<ReferenceManager[]>(`${MODULE_API_URL}${queryKey[1]}/reference_managers`)
      .then((res) => res.data),
  deleteReference: (referenceId: string) =>
    axios.delete(REFERENCE_MANAGER_API_URL + referenceId + '/'),
  getSmartNotes: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<SmartNote[]>(`${MODULE_API_URL}${queryKey[1]}/note_generator`)
      .then((res) => res.data),
  deleteSmartNotes: (smartNoteId: string) => axios.delete(INFOBOX_API_URL + smartNoteId + '/'),
  getCustomNumerics: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<CustomNumeric[]>(`${MODULE_API_URL}${queryKey[1]}/custom_numerics`)
      .then((res) => res.data),
  deleteCustomNumeric: (customNumericId: string) =>
    axios.delete(CUSTOM_NUMERIC_API_URL + customNumericId + '/'),
  getIntegrations: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<Integration[]>(`${MODULE_API_URL}${queryKey[1]}/calculator_integrations`)
      .then((res) => res.data),
  deleteIntegration: (integrationId: string) =>
    axios.delete(CALC_INTEGRATION_API_URL + integrationId + '/'),
  getConditionalTexts: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<ConditionalText[]>(`${MODULE_API_URL}${queryKey[1]}/conditional_texts`)
      .then((res) => res.data),
  deleteConditionalText: (conditionalTextId: string) =>
    axios.delete(CONDITIONAL_TEXT_API_URL + conditionalTextId + '/'),
  postConditionalText: (conditionalTextRequest: ConditionalTextRequest) =>
    axios.post(CONDITIONAL_TEXT_API_URL, conditionalTextRequest).then((res) => res.data),
  putConditionalText: (conditionalTextRequest: ConditionalTextRequest) =>
    axios
      .put(`${CONDITIONAL_TEXT_API_URL}${conditionalTextRequest.id}/`, conditionalTextRequest)
      .then((res) => res.data),
  getApiResources: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<APIResource[]>(
        `${queryKey[0] === MODULE_TYPES.ALGO ? MODULE_API_URL : CALCULATOR_API_URL}${
          queryKey[1]
        }/api_resources`
      )
      .then((res) => res.data),
  deleteApiResource: (apiResourceId: number) =>
    axios.delete(API_RESOURCE_API_URL + apiResourceId + '/'),
  upsertApiResource: (request: APIResourceFormPayload) =>
    (request.id
      ? axios.put(`${API_RESOURCE_API_URL}${request.id}/`, request)
      : axios.post(API_RESOURCE_API_URL, request)
    ).then((res) => res.data),

  // GET calculator resources
  getCalculatorTriggers: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Trigger[]>(`${CALCULATOR_API_URL}${queryKey[1]}/triggers`).then((res) => res.data),
  getCalculatorInfoboxes: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Infobox[]>(`${CALCULATOR_API_URL}${queryKey[1]}/infoboxes`).then((res) => res.data),
  getCalculatorMedia: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Media[]>(`${CALCULATOR_API_URL}${queryKey[1]}/images`).then((res) => res.data),
  getCalculatorFormulas: ({ queryKey }: QueryFunctionContext) =>
    axios.get<Formula[]>(`${CALCULATOR_API_URL}${queryKey[1]}/formulas`).then((res) => res.data),
  getCalculatorReferences: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<ReferenceManager[]>(`${CALCULATOR_API_URL}${queryKey[1]}/references`)
      .then((res) => res.data),
  getCalculatorSmartNotes: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<SmartNote[]>(`${CALCULATOR_API_URL}${queryKey[1]}/note_generator`)
      .then((res) => res.data),
  getCalculatorCustomNumerics: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<CustomNumeric[]>(`${CALCULATOR_API_URL}${queryKey[1]}/custom_numerics`)
      .then((res) => res.data),
  getCalculatorConditionalTexts: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get<ConditionalText[]>(`${CALCULATOR_API_URL}${queryKey[1]}/conditional_texts`)
      .then((res) => res.data),
  getVariables: ({ queryKey }: QueryFunctionContext) => {
    let API_URL =
      queryKey[1] === MODULE_TYPES.CALCULATOR
        ? `${CALCULATOR_API_URL}${queryKey[2]}/variables`
        : `${MODULE_API_URL}${queryKey[2]}/variables`;

    API_URL = queryKey[3] === 'for_text_editor' ? `${API_URL}?for_text_editor=True` : API_URL;
    return axios.get(API_URL).then((res) => res.data);
  },
  getNumerics: ({ queryKey }: QueryFunctionContext) => {
    const API_URL =
      queryKey[1] === MODULE_TYPES.CALCULATOR
        ? `${CALCULATOR_API_URL}${queryKey[2]}/numerics`
        : `${MODULE_API_URL}${queryKey[2]}/numerics`;
    return axios.get(API_URL).then((res) => res.data);
  },
};
