import { Editor } from '@tiptap/react';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { DataToConsume } from '../../../../api/data-extractor';
import { useDataExtractorFormContext } from '../../../module/cards/DataExtractorCard';
import { ExpandableList } from '../../../utils/form-input/ExpandableList';
import { Icons } from '../../../utils/Icons';
import { MentionProvider } from '../../../utils/module/MentionContext';
import { Tiptap } from '../../../utils/tiptap/Tiptap';
import {
  RichTextMenuButtonTypes,
  SuggestionTypeEnum,
  SuggestionTypes,
} from '../../../utils/tiptap/tiptapInterfaces';

const richTextButtonsShowList: RichTextMenuButtonTypes[] = [
  'textStyles',
  'bold',
  'italic',
  'highlight',
  'more',
];

const excludedSuggestionTypes: SuggestionTypes[] = [
  'knowledge_base',
  'conditional_text',
  'infobox',
  'media',
  'link',
  'reference',
  'contact_number',
  'ehr_order',
  'formula',
  'choice_variable',
  'multi_variable',
  'message_variable',
  'ambient_variable',
  SuggestionTypeEnum.DATA_EXTRACTOR,
];

export const DefaultDataToConsume = {
  label: '',
  content: {},
};

export const DataToConsumeListInput = ({
  onDisable,
}: {
  onDisable: (isDisabled: boolean) => void;
}) => {
  const { watch, setValue } = useDataExtractorFormContext();
  const dataToConsume = watch('data_to_consume');
  const [hasEmptyContent, setHasEmptyContent] = useState(
    dataToConsume.some((item) => Object.keys(item.content).length === 0)
  );
  const handleAdd = () => {
    const defaultValue: DataToConsume = { ...DefaultDataToConsume };
    const result = [...dataToConsume, defaultValue];
    setValue('data_to_consume', result);
  };

  useEffect(() => {
    const isEmptyObject = dataToConsume.some((item) => Object.keys(item.content).length === 0);
    onDisable(isEmptyObject || hasEmptyContent || dataToConsume.some((item) => !item.label));
  }, [dataToConsume]);

  return (
    <ExpandableList
      onAdd={handleAdd}
      setValue={(value) => setValue('data_to_consume', value)}
      addItemLabel='Add Data'
      items={dataToConsume}
    >
      <ExpandableList.Items>
        {dataToConsume.map((item, index) => (
          <ExpandableList.Item index={index}>
            <ExpandableList.Button>
              {(open) => (
                <div className='flex w-full items-center space-x-[8px] px-[16px] py-[14px] focus:bg-transparent'>
                  <Icons.ChevronDown className={twMerge(open && 'rotate-180')} />
                  <input
                    className='grow border-b border-gray-300 text-body-1 focus:outline-0 disabled:bg-transparent'
                    value={dataToConsume[index].label}
                    onChange={(e) => {
                      setValue(
                        'data_to_consume',
                        dataToConsume.map((item, itemIndex) =>
                          itemIndex === index ? { ...item, label: e.target.value } : item
                        )
                      );
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <Icons.TrashBin
                    className='cursor-pointer fill-gray-600 hover:fill-primary-600'
                    onClick={(e) => {
                      e.stopPropagation();
                      setValue(
                        'data_to_consume',
                        dataToConsume.filter((_, itemIndex) => itemIndex !== index)
                      );
                    }}
                  />
                </div>
              )}
            </ExpandableList.Button>
            <ExpandableList.Panel>
              <MentionProvider excludedSuggestionTypes={excludedSuggestionTypes}>
                <Tiptap
                  wrapperClassNames='max-h-[600px] min-h-[280px] !h-auto'
                  onUpdate={(editor: Editor) => {
                    setHasEmptyContent(!editor.getText().trim().length);
                    setValue(
                      'data_to_consume',
                      dataToConsume.map((item, itemIndex) =>
                        itemIndex === index ? { ...item, content: editor.getJSON() } : item
                      )
                    );
                  }}
                  initialContent={dataToConsume[index].content as any}
                  placeholder={'Type ‘#’ to add data content.'}
                  richTextButtonsShowList={richTextButtonsShowList}
                />
              </MentionProvider>
            </ExpandableList.Panel>
          </ExpandableList.Item>
        ))}
      </ExpandableList.Items>
    </ExpandableList>
  );
};
