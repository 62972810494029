import { Box, CircularProgress } from '@material-ui/core';
import { setIsFormDisabled } from 'actions/miscellaneous/formHelperAction';
import { getTriggers } from 'actions/resources/getTriggers';
import { resourcesAPI } from 'api/resources';
import axios from 'axios';
import clsx from 'clsx';
import { APIResource } from 'components/resources/api/types';
import AppearingSituation from 'components/utils/AppearingSituation';
import Checkbox from 'components/utils/Checkbox';
import Label from 'components/utils/Label';
import { StaffOnlyBadgeType, StaffOnlyWrapper } from 'components/utils/StaffOnlyWrapper';
import DropdownSingle from 'components/utils/common/DropdownSingle';
import InputField from 'components/utils/form-input/field';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import Overlay from 'components/utils/overlay';
import { Header } from 'components/utils/panels/Header';
import { CustomToast } from 'components/utils/toast-message';
import VariableAutoGenerator from 'components/utils/variable-generator';
import { CANCEL_BUTTON, SAVE_BUTTON } from 'constants/variables';
import { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';
import { getVariableName } from 'utils/gptUtils';
import { getKB, getNumerics, getSuggestions, getVariables } from 'utils/suggestions';
import { API_BOX_API_URL, CARD_API_BOX, MODULE_TYPES } from '../../constants';
import { CancelButton, CreateButton, FixedRow, UnderlyingElement } from './ChoicePanelForm';
import { APIBoxFormDispatchProps } from './types';

interface APIBoxFormState {
  title: string;
  syncVariableName: boolean;
  trigger: any;
  module: any;
  containerCard: any;
  position: any;

  textEditorsuggestions: any;
  variable: string;
  variables: any;
  numerics: any;
  knowledgeBases: any;
  shortCharacterLength: number;
  isLongDescEnabled: boolean;
  promptText: string;
  temp_promptJson: any;
  promptJson: any;
  longCharacterLength: number;
  isLoading: boolean;
  selectedApiResource: number | null;
  apiResources: APIResource[];
  is_hide: boolean;
}

interface APIBoxFormProps {
  handleRichText: (value: any) => void;
  handleAddTitle: (title: string) => void;
  formState: any;
  apiBoxId: number;
  triggerState: any;
  position: number;
  handleSelectedItem: () => void;
  handleAddTrigger: (trigger: any) => void;
  startPreview: (component?: any) => void;
  moduleId: number;
  calculatorId: number;
  toggleModal: () => void;
  resetState: () => void;
  container: any;
  modal?: boolean;
  closeUiElementPane?: (type?: any) => void;
  setIsFormDisabled: (val) => void;
}

type Props = APIBoxFormDispatchProps & APIBoxFormProps;

// TODO: it's mostly copy/paste from gptbox so there are
// lots of unnecessary code to delete
class APIBoxForm extends Component<Props, APIBoxFormState> {
  debouncedOnShortTextChange: any;
  constructor(props: Props) {
    super(props);

    this.state = {
      title: '',
      syncVariableName: false,
      trigger: null,
      module: null,
      position: null,
      containerCard: '',
      textEditorsuggestions: [],
      variable: '',
      variables: [],
      numerics: [],
      knowledgeBases: [],
      shortCharacterLength: 450,
      isLongDescEnabled: false,
      promptText: '',
      temp_promptJson: '',
      promptJson: '',
      longCharacterLength: 1050,
      isLoading: false,
      selectedApiResource: null,
      apiResources: [] as APIResource[],
      is_hide: false,
    };
  }

  setDefaultDescription = () => {
    const short_desc_json = {
      blocks: [
        {
          key: 'opse',
          data: {},
          text: '(The text will be generated based on the prompt you entered.)',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
      ],
      entityMap: {},
    };

    this.setState(
      {
        isLongDescEnabled: false,
      },
      () => {
        this.props.handleRichText(short_desc_json);
        this.props.handleAddTitle('Card Title Placeholder');
      }
    );
  };

  componentDidMount() {
    alert(
      "This is only for product team's internal testing.\nUsing it could lead to some unexpected bugs."
    );
    // TODO: remove when redux data is depracated
    this.props.getTriggers(
      this.props.moduleId ? MODULE_TYPES.ALGO : MODULE_TYPES.CALCULATOR,
      null,
      false,
      this.props.moduleId || this.props.calculatorId
    );
    this.props.setIsFormDisabled(false);

    if (!this.props.apiBoxId) {
      let alwayOnTrigger = this.props.triggerState.triggers.find(
        (data) => data.title === 'Always On'
      );

      this.setState(
        {
          trigger: alwayOnTrigger?.id || '',
          position: this.props.position,
        },
        () => this.setDefaultDescription()
      );

      this.props.handleSelectedItem();
      this.props.handleAddTrigger(alwayOnTrigger ? alwayOnTrigger.id : '');
      this.props.startPreview();
    }

    // if update request encountered
    if (this.props.apiBoxId) {
      axios.get(API_BOX_API_URL + this.props.apiBoxId + '/').then((res) => {
        this.setState({
          title: res.data?.title,
          selectedApiResource: res.data?.api_resource?.id,
          position: res.data?.position,
          trigger: res.data?.trigger.id || '',
          module: res.data?.module,
          variable: res.data?.variable_item?.name,
          is_hide: res.data?.is_hide,
        });
        this.props.handleAddTitle(res.data.title);
        // Trigger already updated in the preview section
        // this.props.handleAddTrigger(res.data.trigger || '');
      });
    }

    this.populateSuggestions();

    resourcesAPI
      .getApiResources({
        queryKey: [
          this.props.moduleId ? MODULE_TYPES.ALGO : MODULE_TYPES.CALCULATOR,
          this.props.moduleId || this.props.calculatorId,
          'api-resources',
        ],
        meta: undefined,
      })
      .then((res) => this.setState({ apiResources: res }));
  }

  getShortCharactersLength = (length: number) => {
    this.setState({ shortCharacterLength: length });
  };

  onTitleChange = (e) => {
    if (e.target.name === 'title') {
      this.props.handleAddTitle(e.target.value);
    }

    this.setState({ [e.target.name]: e.target.value } as Pick<APIBoxFormState, any>);
  };

  onApiResourceChange = (selectedOption: number) => {
    this.setState({ selectedApiResource: selectedOption });
  };

  getPayload = () => {
    return {
      title: this.state.title,
      api_resource: this.state.selectedApiResource,
      trigger: this.state.trigger === 'notAssigned' ? null : this.state.trigger,
      module: this.props.moduleId,
      calculator: this.props.calculatorId,
      position: this.state.position,
      container: this.props.container,
      variable: this.state.variable,
      is_hide: this.state.is_hide,
    };
  };

  createAPIBox = () => {
    this.props.toggleModal();
    axios.post(API_BOX_API_URL, this.getPayload()).then(() => {
      this.props.resetState();
      // fetch triggers to update used in field of triggers
      this.props.getTriggers(
        this.props.moduleId ? MODULE_TYPES.ALGO : MODULE_TYPES.CALCULATOR,
        null,
        false,
        this.props.moduleId || this.props.calculatorId
      );
    });
  };

  editAPIBox = () => {
    const link = API_BOX_API_URL + this.props.apiBoxId + '/';
    this.props.toggleModal();

    axios.put(link, this.getPayload()).then(() => {
      this.props.resetState();
      // fetch triggers to update used in field of triggers
      this.props.getTriggers(
        this.props.moduleId ? MODULE_TYPES.ALGO : MODULE_TYPES.CALCULATOR,
        null,
        false,
        this.props.moduleId || this.props.calculatorId
      );
    });
  };

  populateSuggestions = async () => {
    // suggestions for text field
    const suggestions = await getSuggestions(
      this.props.moduleId || this.props.calculatorId,
      true,
      this.props.moduleId ? MODULE_TYPES.ALGO : MODULE_TYPES.CALCULATOR
    );
    const variables = await getVariables(
      this.props.moduleId || this.props.calculatorId,
      this.props.moduleId ? MODULE_TYPES.ALGO : MODULE_TYPES.CALCULATOR
    );
    const numerics = await getNumerics(
      this.props.moduleId || this.props.calculatorId,
      this.props.moduleId ? MODULE_TYPES.ALGO : MODULE_TYPES.CALCULATOR
    );
    const knowledgeBases = await getKB();
    this.setState({ textEditorsuggestions: suggestions, variables, numerics, knowledgeBases });
  };

  onShortTextChange = (e: any) => {
    const text = e.blocks.map((block) => block.text).join('\n');

    this.setState({
      promptJson: e,
      promptText: text,
    });
    this.props.handleRichText(e);
  };

  getSelectedTrigger = (data: any) => {
    this.setState({ trigger: data });
    this.props.handleAddTrigger(data);
  };

  onKeyPress = (e: any) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const { title, selectedApiResource } = this.state;
    const { apiBoxId, modal, closeUiElementPane } = this.props;

    if (!title) {
      toast.error(CustomToast, { data: 'Please fill out the API Box title field.' });
      return;
    }

    if (!selectedApiResource) {
      toast.error(CustomToast, { data: 'Please select api resource.' });
      return;
    }

    this.setState({ isLoading: true });
    if (!this.state.variable) {
      const variable = await getVariableName(this.state.title);
      this.setState({ variable });
    }

    if (apiBoxId) {
      this.editAPIBox();
    } else {
      this.createAPIBox();
    }
    modal && closeUiElementPane?.();
  };

  render() {
    const isFormDisabled = this.props?.formState?.isFormDisabled || false;
    const triggers = [
      ...this.props.triggerState.triggers,
      ...this.props.triggerState.candidate_triggers,
    ];

    return (
      <div className='row mt-14 h-[97%] overflow-visible'>
        <Overlay show={isFormDisabled} />
        <div className='side-panel-form' style={{ overflow: 'visible' }}>
          <Header title={CARD_API_BOX} toggleModal={this.props.toggleModal} />
          <Form
            onKeyPress={this.onKeyPress}
            autoComplete='off'
            className={clsx('sectionDivider flex flex-col px-3 pb-0', {
              'pointer-events-none': isFormDisabled,
              'pointer-events-auto': !isFormDisabled,
            })}
            onSubmit={this.handleSubmit}
          >
            <Box>
              <AppearingSituation
                triggerOptions={triggers}
                onInputChange={this.getSelectedTrigger}
                defaultValue={this.state.trigger}
                moduleId={this.props.moduleId}
              />
            </Box>

            <Box style={{ marginTop: '25px' }}>
              <InputField
                name='title'
                required={true}
                value={this.state.title}
                onChange={this.onTitleChange}
                label={'Title'}
                placeholder={'title'}
                maxLength={30}
              />
            </Box>

            <Box style={{ marginTop: '25px' }}>
              <FieldLabel required label='API Resource' />
              <DropdownSingle
                placeholder='Select API Resource'
                onChange={(val) => this.onApiResourceChange(val as number)}
                value={this.state.selectedApiResource as number}
                options={this.state.apiResources as any}
              />
            </Box>

            <Box style={{ marginTop: '25px' }} className='mb-4'>
              <VariableAutoGenerator
                tagTitle='API'
                variable={this.state.variable}
                question={this.state.title}
                setVariableName={(variable) => this.setState({ variable })}
              />
            </Box>
            <StaffOnlyWrapper type={StaffOnlyBadgeType.STAFF}>
              <Label className='flex items-center gap-1'>
                <Checkbox
                  checked={this.state.is_hide}
                  onChange={(e) => this.setState({ is_hide: e.target.checked })}
                />
                <span>Hide card</span>
              </Label>
            </StaffOnlyWrapper>

            <div style={{ marginBottom: 180 }}></div>
            <UnderlyingElement />
            <FixedRow>
              <div
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 30,
                  marginBottom: 10,
                }}
              >
                <CancelButton onClick={this.props.toggleModal} style={{ marginRight: '40px' }}>
                  {CANCEL_BUTTON}
                </CancelButton>
                <CreateButton type='submit' name='action'>
                  {this.state.isLoading ? (
                    <CircularProgress id='spinner' style={{ color: '#5adfc9' }} />
                  ) : (
                    SAVE_BUTTON
                  )}
                </CreateButton>
              </div>
            </FixedRow>
          </Form>
        </div>
        <ConfirmModal
          preset='unsaved'
          open={this.props.modal!}
          toggleModal={this.props.closeUiElementPane!}
          toggleModalPanel={this.props.toggleModal!}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({ ...state });

const mapDispatchToProps = (dispatch, APIBoxFormProps) => ({
  getTriggers: (moduleType, mirrorId, sendVariables, moduleId) =>
    dispatch(getTriggers(moduleType, mirrorId, sendVariables, moduleId)),
  setIsFormDisabled: (val) => dispatch(setIsFormDisabled(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(APIBoxForm);
